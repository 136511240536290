<template>
  <ValidationObserver ref="adsDetailsObserver">
    <AdsForm>
      <template #actions>
        <div class="d-flex justify-content-between ads-details-card__footer mt-1">
          <div>
            <b-button variant="primary" @click="onSubmit">تعديل</b-button>
            <b-button variant="outline-danger" class="mx-2" @click="deleteCurrentAds"
              >حذف</b-button
            >
          </div>
          <b-button variant="outline-primary" to="/admin/adsAndOffers">تراجع</b-button>
        </div>
      </template>
    </AdsForm>
  </ValidationObserver>
</template>

<script>
import AdsForm from "../components/AdsForm.vue";
import { mapMutations, mapActions, mapGetters, mapState } from "vuex";

export default {
  components: {
    AdsForm,
  },
  props: {
    id: String,
  },
  computed: {
    ...mapGetters(["activitesList", "adsStatusName", "adsIsActive", "adsIsExpired"]),
    ...mapState({
      adsDto: (state) => state.adsAndOffers.adsDto,
    }),
  },
  created() {
    this.fetchAds(this.id);
  },
  beforeDestroy() {
    this.Reset_Ads_Dto();
  },
  methods: {
    ...mapActions(["fetchAds", "updateAds", "deleteAds", "getActivitiesList"]),
    ...mapMutations([
      "Reset_Ads_Dto",
      "Update_Ads_Image",
      "Update_Ads_Dto_Date",
      "Update_Ads_Dto_Activity_Details",
    ]),

    onSubmit() {
      this.$refs.adsDetailsObserver.validate().then((success) => {
        if (success) {
          let adsDetailsFormData = new FormData();

          adsDetailsFormData.append("id", this.id);

          adsDetailsFormData.append("senderId", this.adsDto.senderId);

          adsDetailsFormData.append("startDate", this.adsDto.startDate);

          adsDetailsFormData.append("endDate", this.adsDto.endDate);

          adsDetailsFormData.append("file", this.adsDto.file);

          adsDetailsFormData.append("content", this.adsDto.content);

          adsDetailsFormData.append("name", this.adsDto.serviceName);

          this.updateAds(adsDetailsFormData);

          this.$refs.adsDetailsObserver.reset();
        }
      });
    },
    beforeDestroy() {
      this.$refs.adsImages.reset();
    },
    deleteCurrentAds() {
      this.deleteAds(this.id);
    },
    selectedActivity() {
      return this.activitesList.find((el) => el.id == this.adsDto.senderId);
    },
  },
};
</script>

<style scoped lang="scss">
.ads-details-card {
  &__header {
    padding: 0rem !important;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
    }
  }

  &__title {
    font-size: 1.4rem;

    color: #5e5873;
  }

  &__ads-status-title {
    font-size: 1.1rem;

    color: #5e5873;
  }

  &__ads-status {
    background-color: rgba(0, 127, 255, 0.12) !important;
    font-size: 1rem;
    border-radius: 200px;
    font-weight: 700;
    padding: 0.4rem;
    width: 60px;
    &--isActive {
      color: #28c76f !important;
    }

    &--isExpired {
      color: #ff9f43 !important;
    }
  }

  &__footer-button {
    padding: 10px 25px;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.4px;
  }

  &__update-button {
    color: #ffffff !important;
  }

  &__remove-button {
    background-color: white !important;
    color: #ff0000 !important;
    border-color: #ff0000 !important;
  }

  &__back-button {
    color: #007fff !important;
    border-color: #007fff !important;
  }
  &__delete-button {
    background-color: #edf4f9 !important;
    color: #000000 !important;
  }
}
</style>
